<template>
  <collapse-card
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Return Sample Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <div class="grid grid-cols-2 gap-x-10 gap-y-4 mb-4">
          <base-input
            v-model="submissionForm.returnDeliveryCompany"
            type="text"
            container-class="flex items-center"
            label="Return Delivery Company"
            label-class="label-w-46"
            :error="submissionFormValidations.returnDeliveryCompany"
            :is-required="returnDeliveryCompanyFieldState === REQUIRED"
          />
          <base-input
            v-model="submissionForm.returnAccountNumber"
            type="text"
            container-class="flex items-center"
            label="Return Account Number"
            label-class="label-w-46"
            :error="submissionFormValidations.returnAccountNumber"
            :is-required="returnAccountNumberFieldState === REQUIRED"
          />
        </div>
        <div class="mb-4">
          <base-button
            variant="btn-primary"
            class="flex items-center"
            size="py-1.5 px-2.5"
            text="Return Address same as Licensee Address"
            type="button"
            @click="matchReturnAndLicenseeAddress()"
          />
        </div>
        <div class="grid grid-cols-2 gap-x-10 gap-y-4 mb-4">
          <base-input
            v-model="submissionForm.returnName"
            type="text"
            container-class="flex items-center"
            label="Return Name"
            label-class="label-w-46"
            :error="submissionFormValidations.returnName"
            :is-required="returnNameFieldState === REQUIRED"
          />
          <base-input
            v-model="submissionForm.returnPhone"
            type="text"
            container-class="flex items-center"
            label="Return Phone"
            label-class="label-w-46"
            :error="submissionFormValidations.returnPhone"
            :is-required="returnPhoneFieldState === REQUIRED"
          />
        </div>
        <base-input
          v-model="submissionForm.returnAddress"
          text-area
          rows="3"
          container-class="w-full flex"
          label="Return Address"
          label-class="label-w-46"
          :error="submissionFormValidations.returnAddress"
          :is-required="returnAddressFieldState === REQUIRED"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { computed, defineAsyncComponent, watch } from 'vue';
import useSubmissionForm from './submissionForm.js';
import { EDITABLE, GENERIC_SUBMISSION_TYPE, INVISIBLE, LICENSEE_STEP, PRESS_RELEASE_SUBMISSION_TYPE, REQUIRED, STANDARD_SUBMISSION_TYPE } from '@/constants/submissions.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';

export default {
    name: 'ReturnSampleDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    setup () {
        const { submissionForm, updateSubmissionForm, submissionFormValidations } = useSubmissionForm();

        // return sample details validations
        const returnDeliveryCompanyFieldState = computed(() => {
            if (submissionForm.returnSample === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? REQUIRED : EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return INVISIBLE;
                } else {
                    return REQUIRED;
                }
            } else {
                return INVISIBLE;
            }
        });
        const returnAccountNumberFieldState = computed(() => {
            if (submissionForm.returnSample === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? REQUIRED : EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return INVISIBLE;
                } else {
                    return REQUIRED;
                }
            } else {
                return INVISIBLE;
            }
        });
        const returnNameFieldState = computed(() => {
            if (submissionForm.returnSample === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? REQUIRED : EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return INVISIBLE;
                } else {
                    return REQUIRED;
                }
            } else {
                return INVISIBLE;
            }
        });
        const returnAddressFieldState = computed(() => {
            if (submissionForm.returnSample === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? REQUIRED : EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return INVISIBLE;
                } else {
                    return REQUIRED;
                }
            } else {
                return INVISIBLE;
            }
        });
        const returnPhoneFieldState = computed(() => {
            if (submissionForm.returnSample === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? REQUIRED : EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return INVISIBLE;
                } else {
                    return REQUIRED;
                }
            } else {
                return INVISIBLE;
            }
        });
        watch(
            () => [submissionForm.returnDeliveryCompany, submissionForm.returnAccountNumber, submissionForm.returnName, submissionForm.returnAddress, submissionForm.returnPhone],
            () => {
                submissionFormValidations.returnDeliveryCompany = returnDeliveryCompanyFieldState.value === REQUIRED && !submissionForm.returnDeliveryCompany ? REQUIRED_FIELD : '';
                submissionFormValidations.returnAccountNumber = returnAccountNumberFieldState.value === REQUIRED && !submissionForm.returnAccountNumber ? REQUIRED_FIELD : '';
                submissionFormValidations.returnName = returnNameFieldState.value === REQUIRED && !submissionForm.returnName ? REQUIRED_FIELD : '';
                submissionFormValidations.returnAddress = returnAddressFieldState.value === REQUIRED && !submissionForm.returnAddress ? REQUIRED_FIELD : '';
                submissionFormValidations.returnPhone = returnPhoneFieldState.value === REQUIRED && !submissionForm.returnPhone ? REQUIRED_FIELD : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        const matchReturnAndLicenseeAddress = () => {
            submissionForm.returnName = submissionForm.licenseeName;
            submissionForm.returnAddress = submissionForm.licenseeAddress;
            submissionForm.returnPhone = submissionForm.contactPhone;
        };
        watch(
            () => submissionForm.returnSample,
            () => {
                updateSubmissionForm({
                    returnDeliveryCompany: '',
                    returnAccountNumber: '',
                    returnName: '',
                    returnPhone: '',
                    returnAddress: ''
                });
            }
        );

        return {
            submissionForm,
            // return sample validations
            REQUIRED,
            submissionFormValidations,
            returnDeliveryCompanyFieldState,
            returnAccountNumberFieldState,
            returnNameFieldState,
            returnAddressFieldState,
            returnPhoneFieldState,
            // return sample
            matchReturnAndLicenseeAddress
        };
    }
};
</script>
